<template>
  <div class="container">
    <my-header :isSenior.sync="isSenior" />
    <div class="paper_box">
      <div class="img_box">
        <img src="../../assets/bkml.png" alt="" class="bkml" />
      </div>

      <div class="table">
        <ul class="number">
          <li class="item" v-for="(v, i) in classifyList" :key="i">
            <div class="title">{{ v.title }}</div>

            <ul class="name">
              <li
                class="item"
                v-for="(v2, i2) in v.childList"
                :key="i2"
                @click="gotoRes2(v2.title)"
              >
                {{ v2.title }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
    
    <script>
import myHeader from "../../components/header/index.vue";
import { getClassifyList, cataloguePager } from "../../api/overseaschinese.js";
export default {
  components: {
    myHeader,
  },
  data() {
    return {
      isSenior: false,
      classifyList: [],
      index: 0,
      catalogueList1: [],
      catalogueList2: [],
      catalogueIndex: 2,
    };
  },
  created() {
    this.getClassifyList();
    this.cataloguePager();
  },

  methods: {
    async cataloguePager() {
      try {
        const res = await cataloguePager({
          level: 3,
          id: "00000000-0000-0000-0000-000000000000",
        });
        this.catalogueList1 = res;
        this.catalogueList2 = res[2].childList;
      } catch (error) {}
    },
    async getClassifyList() {
      try {
        const res = await getClassifyList();
        this.classifyList = res;
      } catch (error) {}
    },

    catalogueList1Click(item, index) {
      if (this.catalogueIndex === index) return;
      this.catalogueIndex = index;
      this.catalogueList2 = item.childList;
    },

    gotoRes2(title) {
      let routeData = this.$router.resolve({
        path: "/overRes",
        query: {
          title,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
    
    <style scoped lang="scss">
.container {
  .paper_box {
    width: 1200px;
    margin: 0 auto;
    margin-top: 10px;

    .bkml {
      width: 1170px;
    }

    .table {
      border: 1px solid #1a416c;
      margin-top: 10px;
      .number {
        .item {
          .title {
            padding: 10px;
            font-size: 18px;
            font-weight: 600;
            border-bottom: 1px solid #1a416c;
            background: #eee;
          }
          .name {
            display: flex;
            flex-wrap: wrap;
            background: #fff;
            .item {
              width: 290px;
              padding: 10px 5px;
              text-decoration: underline;
              color: #4c69bb;
              cursor: pointer;
              font-size: 16px;
            }
            .item:hover {
              color: red;
            }
          }
        }
      }
    }

    .img_box {
      border: 2px solid #1a416c;
      padding: 15px;
    }
  }
  .box {
    width: 1300px;
    padding: 30px 0 0 0;
    margin: 0 auto;

    .search {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      span {
        display: inline-block;
        width: 100px;
      }
      .el-input {
        width: 300px;
      }
    }
  }
  .table {
    width: 1300px;
    padding: 30px 0 0 0;
    margin: 0 auto;

    .title {
      padding: 10px;
      font-size: 18px;
      font-weight: 600;
      border-bottom: 1px solid #1a416c;
      background: #eee;
    }
    .name {
      display: flex;
      flex-wrap: wrap;
      background: #fff;

      .item {
        width: 250px;
        padding: 20px 5px;
        text-decoration: underline;
        color: #4c69bb;
        cursor: pointer;
        font-size: 16px;
      }

      .item:hover {
        color: red;
      }
    }
  }
  .el-pagination {
    text-align: center;
    padding-bottom: 10px;
  }
}
</style>